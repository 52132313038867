import { Injectable } from '@angular/core';
import {InvitationsService} from '../invitations/invitations.service';
import {SessionService} from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor(
      private invitationsService: InvitationsService,
      private sessionService: SessionService,
  ) { }

  public afterSignupPage(response: any, isInvitation: boolean, goalId?: string, amount?: number, team = false): Promise<string> {
    const isAdult = response.user.permissionLevel === 8;
    if (goalId) {
      if (amount) {
        return Promise.resolve(`goal-adjustfunding/${goalId}/${amount}`);
      } else {
        return Promise.resolve('goal-detail/' + goalId);
      }
    }
    if (isAdult) {
      if (isInvitation) {
        return Promise.resolve('/Setup1');
      } else {
        return this.sessionService.refreshToken().then(_ => {
          return `emailcode/${team}`;
        });
      }
    } else {
      // child
      if (isInvitation) {
        return Promise.resolve('/tabs/tab-home');
      } else {
        return Promise.resolve('/Setup1');
      }
    }
  }

  public async nextLoginPage(response: any, goalId?: string, amount?: number) {
    if (goalId) {
      if (amount) {
        return `goal-adjustfunding/${goalId}/${amount}`;
      } else {
        return 'goal-detail/' + goalId;
      }
    }
    if (response.user.firstName && response.user.firstName !== '-' && response.user.lastName  && response.user.lastName !== '-' &&
        (response.user.permissionLevel === 1 || response.user.email || response.phoneNumbers.length > 0)) {
      if (response.user.permissionLevel === 1 || (response.user.address1 && response.user.city &&
          response.user.state && response.user.postalCode && response.user.ssn)) {
        if (response.user.permissionLevel === 1 && response.user.id === response.family.ownerId) {
          return this.invitationsService.getInvitations(response.family.id).then(invitations => {
            return invitations.length ? '/tabs/tab-home' : '/setup-invite-parent';
          });
        } else {
          return '/tabs/tab-home';
        }
      } else {
        return '/tabs/tab-home';
      }
    } else {
      return '/Setup1';
    }
  }

}
